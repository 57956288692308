.homeInfra{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    padding: 20px;
    padding-left: 5%;

  }

  .stake-trackers-wrapper{
    height: 100vh;
  }
  @media screen and (max-width: 1420px) {
    .stake-trackers-wrapper{
      height: 200vh;
    }
  }

  @media screen and (max-width: 1000px) {
    .stake-trackers-wrapper{
      height: 240vh;
    }
  }

  @media screen and (max-width: 690px) {
    .stake-trackers-wrapper{
      height: 130vh;
    }
  }

 