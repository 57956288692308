/* controls the header items text */
.header-bottom{
    position: fixed;
    width: 100%;
    font-family: Vampetica, Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: larger;
}

/* Targets the home page logo */
.logo{
    display: flex;
    position: fixed;
    right: 3vw;
    top: 5px;
}

@media only screen and (max-width: 600px) {
  

    .header-bottom{
        font-size: large;
    }
    .logo{
     
        right: 25px;
        
    }
    
  
 }

  @media only screen and (max-width: 400px) {
  

    .header-bottom{
        font-size: medium;
    }
    .logo{
     
        right: 15px;
        
    }
    
  
  }