@font-face {
  font-family: "Vampetica";
  src: url("https://radixrstaker.com/src/assets/font/webkit/vampetica_regular-webfont.woff") format("woff"),
  }
.header-top {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.header-top > img {
  width: 230px;
}
.icons-collection {
  display: flex;
  align-items: center;
}
.icons-collection .icon-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.icons-collection > * + * {
  margin-left: 7px;
}
.icon-wrapper > * {
  color: #fff;
}

.reddit {
  background-color: #fe3f18;
}

.twitter {
  background-color: #249cce;
}
.lindkedin {
  background-color: #007bb6;
}
.header-bottom {
  background-color: white; /* #fff;*/
  border-bottom: 1px solid #ddd;
  padding: 0.7rem 0rem;
}
.header-bottom nav {
  display: flex;
  align-items: center;
  list-style: none;
  width: 90%;
  margin: auto;
}
/* this is the icon in the nav */
.header-bottom nav li {
  display: flex;
  align-items: center;
  color: white; /* #909192;*/
  margin-right: 10px;
}
/* this is the icon in the nav */
.header-bottom nav li.active-link {
  color: white; /* #007bb6;*/
}
.header-bottom nav li.active-link a {
  color: maroon; /* #007bb6;*/
}
.header-bottom nav a {
  color: grey; /* #020a11;*/
  text-decoration: none;
  margin-left: 5px;
}
.home-body {
  padding: 2rem 0rem;
  width: 95%;
  margin: auto;
  flex-wrap: wrap;
 
}

.home-body .image-wrapper {
  display: flex;
  justify-content: center;
}
.home-body .image-wrapper img {
  width: 30vw;
  padding-top: 30px;
}
.home-body-presentation {
  margin-top: 2rem;
  font-family: Vampetica, Arial, Helvetica, sans-serif;
  text-align: center;

}
.home-body-plain {
  margin-top: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: grey;
  /*display: flex;
  padding: 20px;
  padding-left: 5%;*/
}
.home-body-presentation .top {
  text-align: center;
  background-color: #ffffff;
  padding: 1.5rem;
  width: 70%;
  margin: auto;
  margin-bottom: 2rem;
}
.home-body-presentation .top h1 {
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.home-body-presentation > p {
  margin-bottom: 2rem;
  color: #fff;
}

/* Css for the homepage */
.Home{
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
/* targeting the logo in header */
.logo{
  margin-left: auto;
}
/* hyperlink appearance*/
/* unvisited link */
a:link {
  color: maroon;
  text-decoration: none !important;
}

/* visited link */
a:visited {
  color: maroon;
}

/* mouse over link */
a:hover {
  color: red;
}

/* selected link */
a:active {
  color: red;
}


@media screen and (max-width: 780px) {
  .home-body .image-wrapper img {
    width: 100%;
  }
  .home-body-presentation .top {
    width: 100%;
  }
  .home-body-presentation > p {
    text-align: center;
  }
  
  
}


