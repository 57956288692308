.StakeTracker{
    border: 1px solid lightgray;
    display: flex;
    padding: 10px;
    margin-top: 30px;
    margin-left: 40px;
}

.StakeTrackerDetails{
    margin-right: 20px;
}

.headingTool{
    margin-bottom: 10px;
}

.StakeTrackerImage>img{
    margin-left: 108px;
    width: 250px;
    height: 220px;
}

@media screen and (max-width: 690px) {
    .StakeTrackerDetails{
        font-size: smaller;
    }
    .headingTool{
        font-size: smaller;
    }

    .StakeTrackerImage>img{
        margin-left: 50px;
        height: 100px ;
        width: 100px ;
    }

    .StakeTracker{
        border: 2px solid lightgray;
        display: flex;
        padding: 10px;
        margin-top: 30px;
        margin-left: 20px;
    }
  }