* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "sans-serif";
}

